import { Component, OnDestroy, OnInit, AfterViewChecked, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { MessageService } from '../messages/service/message.service';
import { McsUserService } from '../service/mcs-user.service';
import { Filter } from '../shared/model/filter';
import { DataUploadComponent } from './data-upload/data-upload.component';
import { DataService } from './data/data.service';
import { Company } from '../shared/model/company';
import { Extension } from './model/extension';
import { Filing } from './model/filing';
import { FilingMatrix } from './model/filing-matrix';
import { FilteredFiling } from './model/filtered-filing';
import { LineOfBusiness } from './model/line-of-business';
import { McasState } from './model/mcas-state';
import { StateFiling } from './model/state-filing';
import { User } from '../shared/model/user';
import { UserHistory } from './model/user-history';
import { WarningError } from './model/warning-error';
import { Year } from '../shared/model/year';
import { FilingMatrixService } from './services/filing-matrix.service';
import { ExtensionModalComponent } from './waivers-extensions/extension-modal/extension-modal.component';
import { WaiversExtensionsComponent } from './waivers-extensions/waivers-extensions.component';
import { WaiversModalComponent } from './waivers-extensions/waivers-modal/waivers-modal.component';
import { WarningsErrorsComponent } from './warnings-errors/warnings-errors.component';
import { Subject } from '../../../node_modules/rxjs';
import { takeUntil } from 'rxjs/operators';
import { Waiver } from './model/waiver';
import { environment } from '../../environments/environment';
import { faPlus, faMinus, faBars, faArrowUp, faCaretUp, faCaretDown, faAsterisk, faLock, faUnlock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { CompanyService } from './company-year-select/services/company.service';
import { FileStatus } from './model/file-status';
import * as _ from 'lodash';
import { UserAdminService } from '../user-admin/service/user-admin.service';
import { YearsService } from './company-year-select/services/years.service';
import {
  MultipleExtensionsModalComponent
} from './waivers-extensions/multiple-extensions-modal/multiple-extensions-modal.component';

@Component({
  selector: 'app-filing-matrix',
  templateUrl: './filing-matrix.component.html',
  styleUrls: ['./filing-matrix.component.css']
})
export class FilingMatrixComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChildren('accordionGroup') accordions: QueryList<ElementRef>;
  loading: boolean;
  user: User;
  userHistory: UserHistory[] = [];
  company: Company;
  cocode: number;
  year: Year;
  currentFilingYear: number;
  title: string;
  filingMatrix: FilingMatrix;
  subscriptions: Subscription[] = [];
  filteredFilings: StateFiling[];
  matrixFilteredFilings: FilteredFiling[] = [];
  expanded = false;
  filtersCollapsed = false;
  storedFilteredFilings: StateFiling[];
  checkYear: boolean;
  isSummaryMatrix: boolean = false;
  filterLob: any[] = [];

  isLOBFilterCollapsed = false;
  lobFilters: Filter[] = [];
  lobTerms: string[] = [];

  isRequiredFilterCollapsed = false;
  requiredFilters: Filter[] = [
    new Filter('Required', 'required', false),
    new Filter('Not Required', 'notRequired', false)
  ];
  requiredTerms: string[] = [];

  lockedFilters: Filter[] = [
    new Filter('Locked', 'locked', false),
    new Filter('Unlocked', 'unlocked', false)
  ]
  lockedTerms: string[] = [];

  isFsFilterCollapsed = false;
  fsFilterSelected = 'Filing Status';
  filingStatusFilters: Filter[] = [
    new Filter('In Progress', 'IN_PROGRESS', false),
    new Filter('Filed', 'FILED', false),
    new Filter('Processing', 'PROCESSING', false),
    new Filter('Not Started', 'NOT_STARTED', false)
  ];
  filingStatusTerms: string[] = [];

  isWarningsFilterCollapsed = false;
  warningsFilters: Filter[] = [
    new Filter('Warnings', 'warnings', false),
  ];
  warningsTerms: string[] = [];

  isErrorsFilterCollapsed = false;
  errorsFilters: Filter[] = [
    new Filter('Errors', 'errors', false)
  ];
  errorsTerms: string[] = [];

  isExtensionFilterCollapsed = false;
  extensionFilters: Filter[] = [
    new Filter('Pending', 'PENDING', false),
    new Filter('Approved', 'APPROVED', false),
    new Filter('Denied', 'DENIED', false)
  ];
  extensionTerms: string[] = [];

  isWaiverFilterCollapsed = false;
  waiverFilters: Filter[] = [
    new Filter('Pending', 'PENDING', false),
    new Filter('Approved', 'APPROVED', false),
    new Filter('Denied', 'DENIED', false)
  ];
  waiverTerms: string[] = [];

  bsModalRef: BsModalRef;
  modalSubscriptions: Subscription[];
  stateFilterTerm: string;
  isStateFilterCollapsed = false;
  private unsubscribe: Subject<void> = new Subject();
  isSidebarCollapsed = false;
  isActionsCollapsed = false;
  isCompanyYearSelectCollapsed = false;
  isFiltersCollapsed = true;
  faUnlock = faUnlock;
  faLock = faLock;
  faLockOpen = faLockOpen;
  faPlus = faPlus;
  faMinus = faMinus;
  faBars = faBars;
  faArrowUp = faArrowUp;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faAsterisk = faAsterisk;
  admin: boolean;
  yearUrl: string;
  yearPdf: number;
  companyPdf: string;
  cocodePdf: number;
  statePdf: string;
  stateNamePdf: string;
  lobNamePdf: string;
  lobCodePdf: string;
  printOnlyWarnings: boolean;
  printOnlyErrors: boolean;

  constructor(
    private userService: McsUserService,
    private filingMatrixService: FilingMatrixService,
    private modalService: BsModalService,
    private router: Router,
    public dataService: DataService,
    public messageService: MessageService,
    private companyService: CompanyService,
    private userAdminService: UserAdminService,
    private yearService: YearsService
  ) {
    this.loading = true;
    this.modalSubscriptions = [];
    this.filteredFilings = [];
    this.storedFilteredFilings = [];
  }

  ngOnInit() {
    this.getUser();
    this.isSummaryMatrix = false;
    this.currentFilingYear = this.yearService.currentFilingYear;
  }

  ngAfterViewChecked() {
    if (this.dataService.state && this.accordions && this.accordions.length > 0) {
      let panel = `${this.dataService.state.stabbr}Panel`;
      this.accordions.forEach((accordion, index) => {
        let acc: HTMLDivElement = accordion.nativeElement;
        if (acc.id === panel) {
          let rect: any = acc.getBoundingClientRect();
          if ((rect.x + rect.width) < 0 || (rect.y + rect.height) < 0 || (rect.x > window.innerWidth || rect.y > window.innerHeight)) {
            acc.scrollIntoView();
          }
          this.dataService.state = undefined;
        }
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.messageService.clear();
  }

  getUser(): void {
    this.user = this.userService.getStoredUser();
    this.userHistory = this.userService.getUserHistory();
    this.loading = false;
    if (this.userHistory.length > 0) {
      this.loading = true;
      this.year = this.userHistory[0].year;
      this.company = this.userHistory[0].company;
      this.cocode = this.userHistory[0].cocode;
      this.getMatrix();
    }
  }

  isSuperUser(): boolean {
    return this.user.role === 'MCAS_NAIC_PR';
  }

  setCompanyYear(companyYear: UserHistory) {
    if (companyYear) {
      this.isSummaryMatrix = false;
      this.messageService.clear();
      this.loading = true;
      this.year = companyYear.year;
      this.company = companyYear.company;
      this.cocode = companyYear.cocode;
      this.currentFilingYear = companyYear.currentFilingYear;
      this.getMatrix();
      this.userService.isCurrentYear =  this.year.isCurrentYear;
    } else {
      this.loading = false;
    }
  }

  getMatrix(): void {
    if (this.userAdminService.userAdminCompany) {
      this.company = this.userAdminService.userAdminCompany;
    }
    this.clearFilters();
    this.filingMatrixService.getFilingMatrixByCompanyAndYear(this.company && this.company !== null ? this.company.cocode : this.cocode, this.year)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        filingMatrix => {
          if (!_.isNull(filingMatrix)) {
            this.filingMatrix = filingMatrix;
            this.title = `Filing Matrix for ${this.companyService.getDisplayName(filingMatrix.company.longName, filingMatrix.company.cocode)} - ${filingMatrix.year.year}`;
            this.priorThreeYearData();
            this.setData();
            this.lobFilter();
          } else {
            this.loading = false;
          }
        }
      );
  }

  downloadPriorThreeYearPdf(state: McasState, lineOfBusiness: LineOfBusiness) {
    this.yearPdf = this.filingMatrix.year.year;
    this.companyPdf= this.filingMatrix.company.longName;
    this.cocodePdf= this.filingMatrix.company.cocode;
    this.statePdf= state.stabbr;
    this.stateNamePdf= state.stateName;
    this.lobNamePdf= lineOfBusiness.name;
    this.lobCodePdf= lineOfBusiness.code;
    this.yearUrl = `${environment.isitePDFUrl}cocode=${this.cocodePdf}&year=${this.yearPdf}&stateName=${this.stateNamePdf}&lob=${this.lobCodePdf}&stateAbbr=${this.statePdf}&companyName=${this.companyPdf.replace(' ', '+')}`;
    console.log(this.yearUrl);
  }

  isValidYear(): boolean {
    const currentYear = new Date().getFullYear();
    return this.year.year === currentYear || this.year.year === currentYear - 1;
  }

  priorThreeYearData(): boolean {
    let priorThreeYearFlag: boolean;
    if(this.filingMatrix.year.year === this.currentFilingYear ||
      this.filingMatrix.year.year === this.currentFilingYear-1 ||
      this.filingMatrix.year.year === this.currentFilingYear-2) {
      priorThreeYearFlag = true;
    } else {
      priorThreeYearFlag = false;
    }
    return priorThreeYearFlag;
  }

  private setData(): void {
    this.filteredFilings.length = 0;
    this.storedFilteredFilings.length = 0;
    if (this.filingMatrixService.matrixFilteredFilings.length === 0) {
      this.matrixFilteredFilings = [];
      this.filingMatrix.stateFilings.forEach((stateFiling: StateFiling) => {
        stateFiling.filings.forEach(filing => {
          filing.visible = true;
        });
        this.matrixFilteredFilings.push(new FilteredFiling(stateFiling, false, true));
      });
    } else {
      this.checkFilterIfChecked();
      this.matrixFilteredFilings = [];
      this.matrixFilteredFilings = this.filingMatrixService.matrixFilteredFilings;
    }
    this.matrixFilteredFilings = this.filingMatrixService.setCollapses(this.matrixFilteredFilings);
    this.loading = false;
  }

  checkFilterIfChecked() {
    // Required Filter
    this.filingMatrixService.requiredFilters.length !== 0 ? this.requiredFilters = this.filingMatrixService.requiredFilters : this.requiredFilters = this.requiredFilters;
    // LOB Filter
    this.filingMatrixService.lobFilters.length !== 0 ? this.lobFilters = this.filingMatrixService.lobFilters : this.lobFilters = this.lobFilters;
    // Locked Filter
    this.filingMatrixService.lockedFilters.length !== 0 ? this.lockedFilters = this.filingMatrixService.lockedFilters : this.lockedFilters = this.lockedFilters;
    // Filing Status Filter
    this.filingMatrixService.filingStatusFilters.length !== 0 ? this.filingStatusFilters = this.filingMatrixService.filingStatusFilters : this.filingStatusFilters = this.filingStatusFilters;
    // Warning Filter
    this.filingMatrixService.warningsFilters.length !== 0 ? this.warningsFilters = this.filingMatrixService.warningsFilters : this.warningsFilters = this.warningsFilters;
    // Error Filter
    this.filingMatrixService.errorsFilters.length !== 0 ? this.errorsFilters = this.filingMatrixService.errorsFilters : this.errorsFilters = this.errorsFilters;
    // Extension Filter
    this.filingMatrixService.extensionFilters.length !== 0 ? this.extensionFilters = this.filingMatrixService.extensionFilters : this.extensionFilters = this.extensionFilters;
    // Waiver Filter
    this.filingMatrixService.waiverFilters.length !== 0 ? this.waiverFilters = this.filingMatrixService.waiverFilters : this.waiverFilters = this.waiverFilters;
    // State Filter
    this.filingMatrixService.stateFilterTerm !== '' ? (this.stateFilterTerm = this.filingMatrixService.stateFilterTerm) : this.stateFilterTerm = this.stateFilterTerm;
  }

  lobFilter() {
    if (this.filingMatrixService.lobFilters.length === 0) {
    this.lobFilters = [];
    this.matrixFilteredFilings.forEach(filing => {
      filing.stateFiling.filings.forEach(lob => {
        let newFilter = new Filter(lob.lineOfBusiness.name, lob.lineOfBusiness.code, false);
        if (this.lobFilters.findIndex((filter) => filter.name === lob.lineOfBusiness.name) === -1) {
          this.lobFilters.push(newFilter);
        }
      });
    });
  } else {
    this.lobFilters = this.filingMatrixService.lobFilters;
  }
  }

  clearFilters(): void {
    this.errorsTerms.length = 0;
    this.extensionTerms.length = 0;
    this.filingStatusTerms.length = 0;
    this.requiredTerms.length = 0;
    this.waiverTerms.length = 0;
    this.warningsTerms.length = 0;
    this.lobTerms.length = 0;
    this.stateFilterTerm = '';
    this.lobFilters.forEach((filter: Filter) => {
      filter.filtered = false;
    });
    this.filingStatusFilters.forEach((filter: Filter) => {
      filter.filtered = false;
    });
    this.requiredFilters.forEach((filter: Filter) => {
      filter.filtered = false;
    });
    this.lockedFilters.forEach((filter: Filter) => {
      filter.filtered = false;
    });
    this.warningsFilters.forEach((filter: Filter) => {
      filter.filtered = false;
    });
    this.errorsFilters.forEach((filter: Filter) => {
      filter.filtered = false;
    });
    this.extensionFilters.forEach((filter: Filter) => {
      filter.filtered = false;
    });
    this.waiverFilters.forEach((filter: Filter) => {
      filter.filtered = false;
    });
    this.filterStateNone();
  }

  getRowColor(warnings: number, errors: number): string {
    return FilingMatrixService.rowColor(warnings, errors);
  }

  goToFiling(state: McasState, lineOfBusiness: LineOfBusiness, status: FileStatus) {
    this.dataService.state = state;
    this.dataService.lineOfBusiness = lineOfBusiness;
    this.filingMatrixService.storeCollapses(this.matrixFilteredFilings);
    this.router.navigate(['/filingWizard', {
      year: this.filingMatrix.year.year,
      company: this.filingMatrix.company.longName,
      cocode: this.filingMatrix.company.cocode,
      currentFilingYear: this.currentFilingYear,
      state: state.stabbr,
      stateName: state.stateName,
      lobName: lineOfBusiness.name,
      lobCode: lineOfBusiness.code,
      status: status.code
    }], { skipLocationChange: true });
  }

  goToFilingSummary() {
    this.isSummaryMatrix = true;
    this.filingMatrixService.filingSummaryNavigation = this.isSummaryMatrix;
    this.router.navigate(['/summaryFilingMatrix', {
      year: this.filingMatrix.year.year,
      company: this.filingMatrix.company.longName,
      cocode: this.filingMatrix.company.cocode
    }], { skipLocationChange: true });
  }

  filterByRequired(filter: string) {
    this.requiredTerms = FilingMatrixService.doFiltering(filter, this.requiredTerms);
    this.filingMatrixService.requiredFilters = this.requiredFilters;
    this.filterFilings();
  }

  filterByLocked(filter: string) {
    this.lockedTerms = FilingMatrixService.doFiltering(filter, this.lockedTerms);
    this.filingMatrixService.lockedFilters = this.lockedFilters;
    this.filterFilings();
  }

  filterByLOB(filter: Filter) {
    this.lobTerms = FilingMatrixService.doFiltering(filter.term, this.lobTerms);
    this.filingMatrixService.lobFilters = this.lobFilters;
    this.filterFilings();
  }

  filterByFilingStatus(filter: Filter) {
    this.filingStatusTerms = FilingMatrixService.doFiltering(filter.term, this.filingStatusTerms);
    this.filingMatrixService.filingStatusFilters = this.filingStatusFilters;
    this.filterFilings();
  }

  filterByWarnings(filter: string) {
    this.warningsTerms = FilingMatrixService.doFiltering(filter, this.warningsTerms);
    this.filingMatrixService.warningsFilters = this.warningsFilters;
    this.filterFilings();
  }

  filterByErrors(filter: string) {
    this.errorsTerms = FilingMatrixService.doFiltering(filter, this.errorsTerms);
    this.filingMatrixService.errorsFilters = this.errorsFilters;
    this.filterFilings();
  }

  filterByExtensionStatus(filter: string) {
    this.extensionTerms = FilingMatrixService.doFiltering(filter, this.extensionTerms);
    this.filingMatrixService.extensionFilters = this.extensionFilters;
    this.filterFilings();
  }

  filterByWaiverStatus(filter: string) {
    this.waiverTerms = FilingMatrixService.doFiltering(filter, this.waiverTerms);
    this.filingMatrixService.waiverFilters = this.waiverFilters;
    this.filterFilings();
  }

  filterStateNone(): void {
    this.matrixFilteredFilings.forEach(filteredFiling => {
      filteredFiling.visible = true;
      filteredFiling.stateFiling.filings.forEach(filing => {
        filing.visible = true;
      });
    });
  }

  resetDataForFilters() {
    this.filingMatrixService.matrixFilteredFilings = [];
  }

  filterFilings(): void {
    this.filterStateNone();
    // State Filter
    if (this.stateFilterTerm && this.stateFilterTerm.length > 0) {
      this.filingMatrixService.stateFilterTerm = this.stateFilterTerm;
      this.matrixFilteredFilings.forEach(filteredFiling => {
        if (filteredFiling.stateFiling.state.stateName.toLowerCase().indexOf(this.stateFilterTerm.toLowerCase()) === 0) {
          filteredFiling.visible = true;
        } else {
          filteredFiling.visible = false;
        }
      });
    }
    // LOB Filter
    if (this.lobTerms.length > 0) {
      this.matrixFilteredFilings.forEach(filteredFiling => {
        if (filteredFiling.visible) {
          let visibleCount = 0;
          filteredFiling.stateFiling.filings.forEach(filing => {
            if (filing.visible) {
              if (this.lobTerms.indexOf(filing.lineOfBusiness.code) > -1) {
                filing.visible = true;
                visibleCount++;
              } else {
                filing.visible = false;
              }
            }
          });
          if (visibleCount > 0) {
            filteredFiling.visible = true;
          } else {
            filteredFiling.visible = false;
          }
        }
      });
    }
    // Filing Status Filter
    if (this.filingStatusTerms.length > 0) {
      this.matrixFilteredFilings.forEach(filteredFiling => {
        if (filteredFiling.visible) {
          let visibleCount = 0;
          filteredFiling.stateFiling.filings.forEach(filing => {
            if (filing.visible) {
              if (this.filingStatusTerms.indexOf(filing.status.code) > -1) {
                filing.visible = true;
                visibleCount++;
              } else {
                filing.visible = false;
              }
            }
          });
          if (visibleCount > 0) {
            filteredFiling.visible = true;
          } else {
            filteredFiling.visible = false;
          }
        }
      });
    }
    // Required Filter
    if (this.requiredTerms.length > 0) {
      this.matrixFilteredFilings.forEach(filteredFiling => {
        if (filteredFiling.visible) {
          let visibleCount = 0;
          filteredFiling.stateFiling.filings.forEach(filing => {
            if (filing.visible) {
              const itemRequired = filing.required ? 'required' : 'notRequired';
              if (this.requiredTerms.indexOf(itemRequired) > -1) {
                filing.visible = true;
                visibleCount++;
              } else {
                filing.visible = false;
              }
            }
          });
          if (visibleCount > 0) {
            filteredFiling.visible = true;
          } else {
            filteredFiling.visible = false;
          }
        }
      });
    }
    // Locked Filter
    if (this.lockedTerms.length > 0) {
      this.matrixFilteredFilings.forEach(filteredFiling => {
        if (filteredFiling.visible) {
          let visibleCount = 0;
          filteredFiling.stateFiling.filings.forEach(filing => {
            if (filing.visible) {
              const itemLocked = filing.locked ? 'locked' : 'unlocked';
              if (this.lockedTerms.indexOf(itemLocked) > -1) {
                filing.visible = true;
                visibleCount++;
              } else {
                filing.visible = false;
              }
            }
          });
          if (visibleCount > 0) {
            filteredFiling.visible = true;
          } else {
            filteredFiling.visible = false;
          }
        }
      });
    }

    if (this.warningsTerms.length > 0) {
      this.matrixFilteredFilings.forEach(filteredFiling => {
        if (filteredFiling.visible) {
          let visibleCount = 0;
          filteredFiling.stateFiling.filings.forEach(filing => {
            if (filing.visible) {
              const warnings = filing.warnings.length > 0 ? 'warnings' : '';
              if (this.warningsTerms.indexOf(warnings) > -1) {
                filing.visible = true;
                visibleCount++;
              } else {
                filing.visible = false;
              }
            }
          });
          if (visibleCount > 0) {
            filteredFiling.visible = true;
          } else {
            filteredFiling.visible = false;
          }
        }
      });
    }

    if (this.errorsTerms.length > 0) {
      this.matrixFilteredFilings.forEach(filteredFiling => {
        if (filteredFiling.visible) {
          let visibleCount = 0;
          filteredFiling.stateFiling.filings.forEach(filing => {
            if (filing.visible) {
              const errors = filing.errors.length > 0 ? 'errors' : '';
              if (this.errorsTerms.indexOf(errors) > -1) {
                filing.visible = true;
                visibleCount++;
              } else {
                filing.visible = false;
              }
            }
          });
          if (visibleCount > 0) {
            filteredFiling.visible = true;
          } else {
            filteredFiling.visible = false;
          }
        }
      });
    }

    if (this.extensionTerms.length > 0) {
      this.matrixFilteredFilings.forEach(filteredFiling => {
        if (filteredFiling.visible) {
          let visibleCount = 0;
          filteredFiling.stateFiling.filings.forEach(filing => {
            if (filing.visible) {
              if (filing.extension && this.extensionTerms.indexOf(filing.extension.status) > -1) {
                filing.visible = true;
                visibleCount++;
              } else {
                filing.visible = false;
              }
            }
          });
          if (visibleCount > 0) {
            filteredFiling.visible = true;
          } else {
            filteredFiling.visible = false;
          }
        }
      });
    }

    if (this.waiverTerms.length > 0) {
      this.matrixFilteredFilings.forEach(filteredFiling => {
        if (filteredFiling.visible) {
          let visibleCount = 0;
          filteredFiling.stateFiling.filings.forEach(filing => {
            if (filing.visible) {
              if (filing.waiver && this.waiverTerms.indexOf(filing.waiver.status) > -1) {
                filing.visible = true;
                visibleCount++;
              } else {
                filing.visible = false;
              }
            }
          });
          if (visibleCount > 0) {
            filteredFiling.visible = true;
          } else {
            filteredFiling.visible = false;
          }
        }
      });
    }
    this.filingMatrixService.matrixFilteredFilings = this.matrixFilteredFilings;
  }

  setActivePanels(): void {
    this.expanded = !this.expanded;
    this.dataService.setAllPanelsActive(this.expanded);
    this.matrixFilteredFilings.forEach(filteredFiling => {
      filteredFiling.matrixExpanded = this.expanded;
    });
    this.filingMatrixService.storeCollapses(this.matrixFilteredFilings);
  }

  stateTotalErrors(filings: Filing[]): number {
    let totalErrors: string[] = [];
    filings.forEach((filing: Filing) => {
      if (filing.visible) {
        filing.errors.forEach(error => {
          if (totalErrors.indexOf(error.message) === -1) {
            totalErrors.push(error.message);
          }
        })
      }
    });
    return totalErrors.length;
  }

  stateTotalWarnings(filings: Filing[]): number {
    let totalWarnings: string[] = [];
    filings.forEach((filing: Filing) => {
      if (filing.visible) {
        filing.warnings.forEach(warning => {
          if (totalWarnings.indexOf(warning.message) === -1) {
            totalWarnings.push(warning.message);
          }
        })
      }
    });
    return totalWarnings.length;
  }

  lobWarningLength(items: WarningError[]): number {
    let warningMessage: string[] = [];
    items.forEach(warning => {
      if (warningMessage.indexOf(warning.message) === -1) {
        warningMessage.push(warning.message);
      }
    });
    return warningMessage.length;
  }

  lobErrorLength(items: WarningError[]): number {
    let errorMessage: string[] = [];
    items.forEach(errors => {
      if (errorMessage.indexOf(errors.message) === -1) {
        errorMessage.push(errors.message);
      }
    });
    return errorMessage.length;
  }

  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }

  public openDataFileUpload(): void {
    this.messageService.clear();
    const initialState = {
      company: this.filingMatrix.company,
      year: this.filingMatrix.year
    };

    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.getMatrix();
        this.unsubscribeModal();
      }));

    this.bsModalRef = this.modalService.show(DataUploadComponent, { initialState: initialState, class: 'modal-lg' });
  }

  public openWaiverExtensionRequest(type: string): void {
    this.messageService.clear();
    const initialState = {
      company: this.filingMatrix.company,
      year: this.filingMatrix.year,
      type: type,
      matrix: this.filingMatrix
    };

    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        if (this.bsModalRef.content.updated) {
          this.loading = true;
          this.getMatrix();
        }
        this.unsubscribeModal();
      })
    );

    this.bsModalRef = this.modalService.show(WaiversExtensionsComponent, { initialState: initialState, class: 'modal-lg' });
  }

  public openWarningsErrorsModule(type: string, lineOfBusiness: LineOfBusiness, items: WarningError[]): void {
    this.printOnlyErrors = true;
    this.printOnlyWarnings = true;
    this.messageService.clear();
    let warningsErrorsMessage: string[] = [];
    items.forEach(warningsErrors => {
      if (warningsErrorsMessage.indexOf(warningsErrors.message) === -1) {
        warningsErrorsMessage.push(warningsErrors.message);
      }
    });
    const initialState = {
      type: type,
      lineOfBusiness: lineOfBusiness,
      items: warningsErrorsMessage
    };

    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      })
    );
    this.bsModalRef = this.modalService.show(WarningsErrorsComponent, { initialState: initialState, class: 'modal-lg' });
  }

  public openWaiver(waiver: Waiver): void {
    this.messageService.clear();
    const initialState = {
      waiver: waiver,
      company: this.company
    };

    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        if (this.bsModalRef.content.updated) {
          this.loading = true;
          this.getMatrix();
        }
        this.unsubscribeModal();
      })
    );

    this.bsModalRef = this.modalService.show(WaiversModalComponent, { initialState: initialState, class: 'modal-lg' });
  }

  public openMultipleExtensions(extension: Extension, lob: LineOfBusiness, state: McasState): void {
    this.messageService.clear();
    const initialState = {
      extension: extension,
      company: this.company,
      year: this.year,
      state: state,
      lob: lob
    };

    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        if (this.bsModalRef.content.updated) {
          this.loading = true;
          this.getMatrix();
        }
        this.unsubscribeModal();
      })
    );

    this.bsModalRef = this.modalService.show(MultipleExtensionsModalComponent, { initialState: initialState, class: 'modal-lg' });
  }

  public openExtension(extension: Extension): void {
    this.messageService.clear();
    const initialState = {
      extension: extension,
      company: this.company
    };

    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        if (this.bsModalRef.content.updated) {
          this.loading = true;
          this.getMatrix();
        }
        this.unsubscribeModal();
      })
    );

    this.bsModalRef = this.modalService.show(ExtensionModalComponent, { initialState: initialState, class: 'modal-lg' });
  }

  hasVisibleResults(): boolean {
    let visibleCount = 0;
    this.matrixFilteredFilings.forEach(ff => {
      if (ff.visible) {
        visibleCount++;
      }
    });
    return visibleCount > 0;
  }

  goToCompanyRatios(): void {
    window.open(`${environment.companyRatiosUrl}cocode=${this.filingMatrix.company.cocode}&year=${this.filingMatrix.year.year}&systemCode=2`, '_blank');
  }

  hasCompanies(): boolean {
    return this.user.cocodesAsPrimary.concat(this.user.cocodesAsSecondary).length > 0;
  }

  isExternalUser(): boolean {
    return this.userService.isExternalUser();
  }

  isCurrentYear(): boolean {
    return this.userService.isCurrentYear;
  }

  getInputStyle(): string[] {
    if (this.printOnlyErrors === true || this.printOnlyWarnings === true) {
      return ['pt-3', 'pb-3', 'main-content', 'd-print-none'];
    } else {
      return ['pt-3', 'pb-3', 'main-content', 'd-print-block'];
    }
  }

}
